<script>
import Layout from "@/views/pages/super_admin/layout/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import Swal from 'sweetalert2';
import MagicTable from "@/components/MagicTable/MagicTable";



    /**
     * Add-product component
     */
    export default {
        page: {
            title: "Team",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,MagicTable
          
        },
   
        data() {
            return {
             
                title: "Restaurants",
                restaurants:[],
                fetch_url:'/restaurant/index',
                items: [
                    {
                        text: "Dashboard",
                    },
                    {
                        text: "Restaurants",
                        active: true,
                    },
                ],
            };
        },



  mounted() {
    
  },
  methods: {
        updateRecords(records) {
            this.restaurants = records;
        },
     
    deleteRestaurant(id){
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
              this.$axios.delete('/restaurant/delete/'+id)
                .then(response => {
                    let data = response.data;
                //    this.triggerSwal(data.message, "success");
                    this.restaurants = this.restaurants.filter((item) => (item.user.id != id));
                                     
                    Swal.fire("Deleted!", data.message, "success");
                }).catch(error => {
                this.handleErrorResponse(error.response, "error");
                }).finally(function () {
                });
        }
      }); 
        
    },

  },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
         <div class="card">
   <div class="card-body">
     <magic-table :fetch_url_prop="fetch_url" @recordsUpdated="updateRecords">

                            <template v-slot:table>

                                 <div class="row mt-4 ">
      <div class="col-lg-12">
      
            <div class="table-responsive">
              <table class="table mb-0 table-bordered">
                <thead>
                  <tr>
                    <!-- <th class="f-14 fw-semiBold roboto">Sr. #</th> -->
                    <th class="f-14 fw-semiBold roboto">Restaurant Name</th>
                    <th class="f-14 fw-semiBold roboto">Restaurant Country</th>
                    <th class="f-14 fw-semiBold roboto">Owner Name</th>
                    <th class="f-14 fw-semiBold roboto">email</th>
                    <th class="f-14 fw-semiBold roboto">Phone</th>
                    <th class="f-14 fw-semiBold roboto">created_at</th>
                    <th class="f-14 fw-semiBold roboto">Action</th>
                    
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(restaurant,key) in restaurants" :key="key">
                    <!-- <td scope="row" class="f-14 roboto pColor">{{key+1}}</td> -->
                    <td scope="row" class="f-14 roboto pColor">{{restaurant.name}}</td>
                    <td scope="row" class="f-14 roboto pColor">{{restaurant.country}}</td>
                    <td scope="row" class="f-14 roboto pColor">{{restaurant.user.name}}</td>
                    <td scope="row" class="f-14 roboto pColor ">{{restaurant.user.email}}</td>
                    <td scope="row" class="f-14 roboto pColor ">{{restaurant.user.phone}}</td>
                    <td scope="row" class="f-14 roboto pColor">{{restaurant.user.created_at_formatted}}</td>
                    <td scope="row" class="f-14 roboto pColor">
                        <ul class="d-flex list-inline mb-0">
                            <li class="list-inline-item">
                       <router-link v-b-tooltip.hover title="Edit" :to="{ name: 'edit_profile_restaurant', params: { restaurant_id: restaurant.id }}">
                                                         <i class="uil uil-pen font-size-18"></i>
                                                    </router-link>
                                                    </li>
                                                    <li class="list-inline-item">
                                                    <a href="javascript:void(0);" class="px-2 text-danger" v-b-tooltip.hover @click="deleteRestaurant(restaurant.user.id)" title="Delete">
                                                        <i class="uil uil-trash-alt font-size-18"></i>
                                                    </a>
                                                </li>
                                                </ul>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
       
      </div>
    </div>

                            </template>
                        </magic-table>
     </div>
  </div>
    </Layout>
</template>
